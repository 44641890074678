import React from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router, Route, Switch, Link, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

// components
import Header from './Components/Header';
import BottomNav from './Components/BottomNav';

// sections
import Products from './Pages/Products';
import ProductDetail from './Pages/ProductDetail';

// ApolloClient
const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri: 'https://ionic.hostexcelify.com/graphql',
});

// Styles
const useStyles = makeStyles(theme => ({
  offset: theme.mixins.toolbar,
}))

function App() {
  const classes = useStyles();
  return (
    <ApolloProvider client={client}>
      <Router>
        <Header />
        <div className={classes.offset} />
        <Switch>
          <Route exact path="/" component={Products} />
          <Route path="/products/:slug" component={ProductDetail} />
          <Route path="/products/" component={Products} />
          <Route render={() => <h1>404: page not found</h1>} />
        </Switch>
        <div className={classes.offset} />
        <BottomNav />
      </Router>
    </ApolloProvider>
  );
}
export default App;