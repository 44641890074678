import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@material-ui/core';
import PageTitle from './PageTitle';
import { Link } from 'react-router-dom';


function Header() {
   return (
      <AppBar position="sticky">
         <Toolbar>
            <Typography variant="h6">
               Product Matrix / <PageTitle title="Home"/>
             </Typography>
            <Button edge="end" color="inherit" component={Link} to={'/'}>Back to Home</Button>
         </Toolbar>
      </AppBar>
   );
}
export default Header