import React, { useState, useMemo, useCallback, Fragment, useEffect } from "react";
import { useQuery, useLazyQuery, gql } from '@apollo/client';
import {
  Grid,
  Card,
  Container,
  Paper,
  CardActionArea,
  CardMedia,
  makeStyles,
  CardContent,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  FormGroup,
  FormControlLabel,
  TextField,
  MenuItem,
  Grow,
  CircularProgress
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import { Link } from 'react-router-dom';


// styles
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const get_posts = gql`
query GetPosts {
   posts(first: 100, where: {orderby: {field: TITLE, order: ASC}}) {
    edges {
      node {
        id
        slug
        title
        excerpt
        acf {
          featImg {
            sourceUrl(size: LARGE)
          }
        }
        categories {
          edges {
            node {
              categoryId
              name
              id
              slug
            }
          }
        }
      }
    }
    pageInfo {
      total
      hasNextPage
      endCursor
    }
  }
  categories {
    edges {
      node {
        categoryId
        id
        name
        slug
      }
    }
  }
}
`;

const sortOptions = [
  { value: '', label: 'None' },
  { value: 'asc', label: 'Ascending' },
  { value: 'desc', label: 'Descending' }
];

const Products = () => {
  const classes = useStyles();
  const { loading, error, data } = useQuery(get_posts);

  //useSate for search and filtering
  const [genre, setGenre] = useState([]);
  const [keyword, setKeyword] = useState("");

  //useSate for pagination
  const [page, setPage] = useState(1);
  const [postPerPage] = useState(12);

  //useState for sorting ASC or DEC
  const [sortType, setSortType] = useState('');


  //get select values
  const handleSelectChange = e => {
    setSortType(e.target.value);
  };

  //get checkbox values
  const handleChange = e => {
    if (e.target.checked) {
      setGenre([...genre, e.target.value]);
    } else {
      setGenre(genre.filter(id => id !== e.target.value));
    }
  };

  //get search values
  const searchChange = e => {
    setKeyword(e.target.value);
  };

  // apollo hooks load data
  if (loading) return (
  <div style={{ width: '100%', height: '100%' }}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
  </div>
  );
  if (error) return <p>Error :( {error.message}</p>;
  if (!data) return <p>No Data</p>;

  // example... filteredGenre data must be checked to display filtered results
  //
  // const filteredGenre = data.posts.edges.map(post => ({
  //   title: post.node.title,
  //   genre: post.node.categories.edges.map(cat => cat.node.name)
  // })).filter(post =>
  //   (!keyword || post.title.toLowerCase().includes(keyword.toLowerCase())) &&
  //   (!genre || genre.some(category => [post.genre].flat().includes(category))),
  //   console.log('filtered: ' + genre)
  // )

  // set GraphQL data end-points
  let myData = data.posts.edges.map(post => ({
    title: post.node.title,
    slug: post.node.slug,
    genre: post.node.categories.edges.map(cat => cat.node.name),
    // featImgUrl: post.node.acf.featImg.sourceUrl,
  }));

  //set filteredGenre data to filters for keyword and checkboxes
  //show data before search or checked
  let filteredGenre;
  if (genre.length === 0) {
    filteredGenre = myData.filter(post =>
      (!keyword || post.title.toLowerCase().includes(keyword.toLowerCase()))
    );
  } else {
    filteredGenre = myData.filter(post =>
      (!keyword || post.title.toLowerCase().includes(keyword.toLowerCase())) &&
      (!genre || genre.some(category => [post.genre].flat().includes(category))),
      console.log('filtered: ' + genre)
    );
    console.log('genre: ' + genre);
  }


  // let ListGallery;
  // if (data.posts.edges.map(post => post.node.acf.featImg.sourceUrl) == null) {
  //   ListGallery = <h3> No Data Found </h3>;
  //   console.log('ListGallery false: ' + ListGallery);
  // } else {
  //   ListGallery = data.posts.edges.map((post, i) =>
  //   <Grid item sm={3}>
  //   <Paper style={{ height: '100%' }}>
  //     <Card my={4} style={{ height: '100%' }}>
  //       <CardMedia
  //         component="img"
  //         // alt={post.featImgUrl}
  //         height="300"
  //         image={post.node.acf.featImg.sourceUrl}
  //         // title={post.featImgUrl}
  //       />
  //     </Card>
  //   </Paper>
  // </Grid>
  //   ); //end map
  //   console.log('ListGallery true: ' + ListGallery);
  // }


  return (

    <Fragment>
      <Container >
        <Grid container>
          <Grid item sm={3}>
            <div>
              <input
                value={keyword}
                type="text"
                onChange={searchChange}
                placeholder="search..."
              />
            </div>
            <Typography gutterBottom variant="h6" noWrap>
              Category
          </Typography>
            <FormControl>
              <FormGroup>
                <p>Total Posts: {data.posts.pageInfo.total}</p>
                {data.categories.edges.map((cat, i) => (
                  <FormControlLabel key={i}
                    control={<Checkbox onChange={handleChange} />}
                    label={cat.node.name}
                    value={cat.node.name}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item sm={9}>


            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">Sort</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={sortOptions}
                onChange={handleSelectChange}
                label="Sort"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="asc">Ascending</MenuItem>
                <MenuItem value="dec">Descending</MenuItem>
              </Select>
            </FormControl>


            {/* <TextField style={{ marginBottom: '30px' }} value={genre} fullWidth onChange={handleChange} /> */}
            <Grid container alignItems="stretch" spacing={3}>



              {/* {data.posts.edges.map((post,i) => 
        <div key={i}>
            {post.node.featuredImage.map((img, i) => 
                  <div key={i}>
                  {img.node.sourceUrl}
                  </div>
            )}
        </div>
      )} */}


              {filteredGenre.slice((page - 1) * postPerPage, page * postPerPage).map((post, i) => (
                // {filteredGenre.map((post, i) => (

                <Grid item key={i} xs={12} sm={6} md={4}>
                  <Paper style={{ height: '100%' }}>
                    <Card>
                    <Link to={`/products/${post.slug}`}>


                      <CardContent>
                        <Typography gutterBottom variant="h5" noWrap>
                          {post.title}
                        </Typography>

                        <Typography gutterBottom variant="h6" noWrap>
                          genre: {post.genre}
                        </Typography>
                      </CardContent>
                      </Link>
                    </Card>
                  </Paper>
                </Grid>
              ))}
            </Grid>
            <div className={classes.root}>
              <Pagination
                page={page}
                onChange={(e, value) => setPage(value)}
                count={Math.ceil(myData.length / postPerPage)}
                color="primary"
                showFirstButton
                showLastButton
                // variant="outlined"
                // size="small"
                defaultPage={1}
              />
            </div>

          </Grid>

        </Grid>
      </Container>
    </Fragment>

  );
};

export default Products;
