import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { BottomNavigationAction, BottomNavigation } from '@material-ui/core';

import FolderIcon from '@material-ui/icons/Folder';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles({
   root: {
      width: 500,
   },
   stickToBottom: {
      width: '100%',
      position: 'fixed',
      bottom: 0,
   },
});

export default function BottomNav() {
   const classes = useStyles();
   const [value, setValue] = useState('recents');

   const handleChange = (event, newValue) => {
      setValue(newValue);
   };

   return (
      <BottomNavigation value={value} onChange={handleChange} className={classes.stickToBottom}>
         <BottomNavigationAction href="/products/" label="Products" value="Products" icon={<RestoreIcon />} />
         <BottomNavigationAction href="/products/bears" label="Bears" value="Bears" icon={<RestoreIcon />} />
         <BottomNavigationAction href="/products/cubs" label="Cubs" value="Cubs" icon={<RestoreIcon />} />
      </BottomNavigation>
   );
}
