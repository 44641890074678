import React, { useState } from "react";
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { useParams } from 'react-router-dom';

import {
  Grid,
  Card,
  Container,
  Paper,
  CardMedia,
  makeStyles,
  CardContent,
  Typography,
  Button,
  Box,
  CircularProgress,
} from "@material-ui/core";
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';

const GetPostBySlug = gql`
query postQuery($slug: String!) {
   post: postBy(slug: $slug) {
       id
       title
       slug
       content
       acfPosts {
        productGallery {
          sourceUrl(size: THUMBNAIL)
          sourceUrlLg: sourceUrl(size: LARGE)
          id
          title
          slug
          altText
          description
          caption
        }
      }
   }
 }
`;

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

function ProductDetail() {
  const { slug } = useParams();
  const { loading, error, data } = useQuery(GetPostBySlug, {
    variables: { slug },
  });

  const classes = useStyles();
  const history = useHistory();

  //rodal modal
  const [modalImage, setModalImage] = useState([]);
  const [visible, setVisible] = useState(false);

  const show = modalImage => () => {
    setVisible(true);
    setModalImage({
      img: modalImage.sourceUrlLg,
      alt: modalImage.altText,
      des: modalImage.description,
      cap: modalImage.caption,
      title: modalImage.title,
    });
    console.log(modalImage.img);
  };
  const hide = () => {
    setVisible(false);
  };

  if (loading) return (
    <div style={{ width: '100%', height: '100%' }}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
    </div>
    );
  if (error) return <p>Error :(</p>;
  if (!data) return <p>No Data</p>;

  let ListGallery;
  if (data.post.acfPosts.productGallery == null) {
    ListGallery = <h3> No Data Found </h3>;
    console.log('ListGallery false: ' + ListGallery);
  } else {
    ListGallery = data.post.acfPosts.productGallery.map((gal, i) =>
      <Grid item sm={3} key={i}>
        <Paper style={{ height: '100%' }}>
          <Card my={4} style={{ height: '100%' }} onClick={show(gal)}>
            <CardMedia
              component="img"
              alt={gal.altText}
              height="300"
              image={gal.sourceUrl}
              title={gal.title}
            />
            {/* <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {gal.title}
              </Typography>
            </CardContent> */}
          </Card>
        </Paper>
      </Grid>
    ); //end map
    console.log('ListGallery true: ' + ListGallery);
  }

  return (
    <Container>
        <Grid style={{ marginBottom: '20px' }}>
          <Button
              size="small"
              variant="contained"
              color="default"
              className={classes.button}
              startIcon={<KeyboardReturnIcon />}
              onClick={() => { history.goBack(); }}
            >
              Back To Products
          </Button>
        </Grid>
        <h1>{data.post.title}</h1> 


        {/* <div dangerouslySetInnerHTML={{ __html: data.post.content }} /> */}

        <Grid container alignItems="stretch" spacing={3}>
          {ListGallery}
        </Grid>

        <Rodal
          visible={visible}
          onClose={hide}
          width="800"
          height="100%"
        >
          <Grid>
            <Card>
              <CardMedia
                component="img"
                image={modalImage.img}
                alt={modalImage.alt}
                title={modalImage.title}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {modalImage.title}
                </Typography>
                <Typography gutterBottom variant="p" component="p">
                  <div dangerouslySetInnerHTML={{ __html: modalImage.des }} />
                </Typography>
                <Typography gutterBottom variant="p" component="p">
                  <div dangerouslySetInnerHTML={{ __html: modalImage.cap }} />
                </Typography>
                <Typography gutterBottom variant="p" component="p">
                  {modalImage.alt}
                </Typography>
              </CardContent>
            </Card>

            {/* <img src={modalImage.img} alt={modalImage.img} /> */}
          </Grid>
        </Rodal>

    </Container>
  );
}

export default ProductDetail;
